import { observer } from "mobx-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { LayoutContext } from "../auth";
import { Table, Button, Upload, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { columns } from "./const";
import {
  getSurveys,
  getStatics,
  downloadSurveyStastic,
  downloadSurveyTemplate,
} from "../../utils/request";
import styles from "../../css/adminSurvey.module.scss";
import { downloadBlob } from "../../utils/utils";

function AdminSurvey() {
  const { setHead } = useContext(LayoutContext);
  const [datas, setDatas] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [activeSurvey, setActiveSurvey] = useState(null);
  const token = localStorage.getItem("token");
  const uploadOptions = {
    name: "file",
    action: "/v1/upload/survey",
    showUploadList: false,
    headers: {
      authorization: `Bearer ${token}`,
    },
    beforeUpload(file) {
      const extName = file.name.split(".").pop().toLowerCase();
      const types = ["xls", "xlsx"];
      if (!types.includes(extName)) {
        message.error(`You must upload xls/xlsx`);
        return false;
      }
      return true;
    },
    onChange(info) {
      if (info.file.status === "done") {
        getAllSurveys();
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const getAllSurveys = useCallback(() => {
    getSurveys().then((res) => {
      setSurveys(res.data);
      const defaultSurveyId = res.data[0]?.id;
      if (defaultSurveyId) {
        setActiveSurvey(defaultSurveyId);
        getStaticsData(defaultSurveyId);
      }
    });
  }, []);

  useEffect(() => {
    setHead({
      title: "调查问卷",
    });
    getAllSurveys();
  }, [setHead, getAllSurveys]);

  function getStaticsData(id) {
    getStatics(id).then((res) => {
      setDatas(res.data);
    });
  }
  function onChangeSurvey(surveyId) {
    setActiveSurvey(surveyId);
    getStaticsData(surveyId);
  }
  function downloadExcel() {
    downloadSurveyStastic(activeSurvey).then((res) => {
      downloadBlob(res, "调查问卷");
    });
  }
  function downloadTemp(e) {
    e.stopPropagation();
    downloadSurveyTemplate().then((res) => {
      downloadBlob(res, "问卷导入模板");
    });
  }
  return (
    <div className={styles.page}>
      <div className={styles.pageWrap}>
        <div className={styles.actions}>
          <Upload {...uploadOptions}>
            <div className={styles.uploadBtns}>
              <Button type="dashed">上传excel导入问卷</Button>
              <Button
                type="dashed"
                icon={<DownloadOutlined />}
                onClick={downloadTemp}
              ></Button>
            </div>
          </Upload>
          {surveys.map((k) => (
            <Button
              type={activeSurvey === k.id ? "primary" : "default"}
              onClick={() => onChangeSurvey(k.id)}
              key={k.id}
            >
              {k.title}
            </Button>
          ))}
        </div>
        <Table pagination={false} columns={columns} dataSource={datas} />
        <div className={styles.export}>
          <Button type="primary" onClick={downloadExcel}>
            导出excel表格
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(AdminSurvey);
