import { makeAutoObservable } from "mobx";
import { getProfile } from "../utils/request";
import { create, persist } from 'mobx-persist'


// Todo 用户信息
class UserStore {
  profile = null
  loading = false
  isAuth = true;
  isAdmin = true;

  constructor() {
    makeAutoObservable(this);
  }

  async refresh() {
    this.loading = true
    const profile = await getProfile()
    this.loading = false
    this.profile = profile.data
    this.isAuth = true
    this.isAdmin = profile.data.isAdmin
    return profile
  }

  loadTodo(url) {
    fetch(url)
      .then((res) => res.json())
      .then((data) => (this.todos = data));
  }
}

const schema = {
  profile: 'object'
}


const hydrate = create({
  storage: localStorage,   // or AsyncStorage in react-native.
                          // default: localStorage
  jsonify: true  // if you use AsyncStorage, here shoud be true
                  // default: true
})

const userStore = persist(schema)(new UserStore())

hydrate('userStore', userStore).then(() => console.log('userStore has been hydrated'))

export default userStore;
