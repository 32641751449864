import { observer } from "mobx-react";
import styles from "../../css/UserReflection.module.scss";
import titleImg from "../../img/title.png";
import waitImg from "../../img/waiting.png";
import Stars from "../../components/stars";
import { useParams } from "react-router";
import { useCallback, useEffect, useState } from "react";
import {
  getBook,
  getBookChallenge,
  getBookReflection,
  getBookEvaluate,
} from "../../utils/request";
import goldImg from "../../img/gold.png";
import silverImg from "../../img/silver.png";
import copperImg from "../../img/copper.png";
import { useTranslation } from "react-i18next";
function UserReflection() {
  const [book, setBook] = useState({});
  const { id, challengeId } = useParams();
  const [rating, setRating] = useState(0);
  const [levelTxt, setLevelTxt] = useState("");
  const [levelImg, setLevelImg] = useState("");
  
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [mark, setMark] = useState(0);
  const [bookMark, setBookMark] = useState(0);
  const [bookVote, setBookVote] = useState(0);
  const [reflectionRating, setReflectionRating] = useState(0);
  const [totalRating, setTotalRating] = useState(0);
  const { t } = useTranslation()

  const getReflection = useCallback(
    (rating) => {
      getBookReflection(id, challengeId).then((res) => {
        setTitle(res.data.title);
        setContent(res.data.content);
        setMark(res.data.mark);
        if (res.data.rating) {
          setReflectionRating(res.data.rating);
          setTotalRating(rating + res.data.rating);
        }
      });
    },
    [id, challengeId]
  );

  const getChallengeInfo = useCallback(() => {
    return getBookChallenge(challengeId).then((res) => {
      setRating(() => {
        getReflection(res.data.rating);
        return res.data.rating;
      });
      getLevel(res.data.rating);
    });
  }, [challengeId, getReflection]);

  const getEvaluate = useCallback(() => {
    getBookEvaluate(id).then((res) => {
      setBookMark(res.data.mark);
      setBookVote(res.data.vote);
    });
  }, [id]);

  const getBookInfo = useCallback(() => {
    getBook(id).then((res) => {
      setBook(res.data);
    });
  }, [id]);

  useEffect(() => {
    getBookInfo();
    getChallengeInfo();
    getEvaluate();
  }, [getBookInfo, getChallengeInfo, getEvaluate]);

  function getLevel(rating) {
    if (rating >= 80) {
      setLevelTxt(t('winGoldBadge'));
      setLevelImg(goldImg);
    } else if (rating >= 70) {
      setLevelTxt(t('winSilverBadge'));
      setLevelImg(silverImg);
    } else if (rating >= 50) {
      setLevelTxt(t('winCopperBadge'));
      setLevelImg(copperImg);
    }
  }

  return (
    <div className={styles.page}>
      <div className={styles.pageContent}>
        <div className={styles.contentLeft}>
          <div className={styles.contentTitle}>
            <img src={titleImg} alt="title"></img>
          </div>
          <div className={styles.contentBook}>
            <div className={styles.bookCover}>
              <img src={book.cover} alt="cover"></img>
            </div>
            <div className={styles.bookInfo}>
              <p className={styles.bookName}>{book.title}</p>
              <Stars value={bookMark} count={bookMark} disabled></Stars>
              <p className={styles.bookVote}>{t('vote')}：{bookVote}</p>
            </div>
          </div>
          <div className={styles.contentBless}>
            <p>恭喜你！</p>
            <p>完成了《{book.title}》答题挑战</p>
            <p>
              你的分数是<span>{rating}</span>分 {levelTxt}
            </p>
          </div>
          <div className={styles.aura}></div>
          {levelImg && (
            <img className={styles.badge} src={levelImg} alt="level"></img>
          )}
        </div>
        
        <div className={styles.contentRight}>
          <p className={styles.contentTitle}>
            读后感分数分数是：
            <span>{reflectionRating ? reflectionRating : "等待老师评分"}</span>
          </p>
          <div className={styles.reflectionInfo}>
            <p className={styles.reflectionTitle}>{title}</p>
            <p className={styles.reflectionContent}>{content}</p>
          </div>
          <div className={styles.contentRating}>
            <Stars count={mark} value={mark} disabled></Stars>
            <div className={styles.ratingTotal}>
              <p>总分：</p>
            </div>
          </div>
          <div className={styles.complete}></div>
          <div className={styles.waiting}>
            {!totalRating ? (
              <img src={waitImg} alt="wait"></img>
            ) : (
              <span>{totalRating ? totalRating : ""}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default observer(UserReflection);
