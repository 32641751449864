import { observer } from "mobx-react";
import styles from "../../css/userFeedBack.module.scss";
import { Input, message, Modal } from "antd";
import { addFeedBack } from "../../utils/request";
import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../auth";
import { useTranslation } from "react-i18next";

function UserFeedBack() {
  const [content, setContent] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { setHead } = useContext(LayoutContext);
  const { t } = useTranslation()

  useEffect(() => {
    setHead({
      needMenu: true,
    });
  }, [setHead]);

  function submit() {
    if (!content) {
      message.warning(t('hint.pleaseInputFeedbackContent'));
      return;
    }
    addFeedBack({
      content,
    }).then(() => {
      setIsOpen(true);
      message.success(t('hint.submitSuccess'));
    });
  }
  return (
    <div className={styles.page}>
      <div className={styles.pageWrap}>
        <Input.TextArea
          onChange={(e) => setContent(e.target.value)}
          maxLength={1000}
          placeholder={t('feedbackPlaceholder')}
        />
        <div className={styles.submitBtn} onClick={submit}>
          {t('submit')}
        </div>
      </div>
      <div className={styles.wrapLeftBg}></div>
      <div className={styles.wrapRightBg}></div>
      <Modal
        open={isOpen}
        footer={null}
        closable={false}
        wrapClassName={styles.modalContent2}
        width={945}
        onCancel={() => setIsOpen(false)}
        style={{ top: "228px" }}
      >
        <div>
          <p className={styles.feedMsg}>{t('hint.thankYouForFeedback')}</p>
          <p className={styles.feedMsg}>{t('hint.teacherWillReview')}</p>
          <div className={styles.feedBtn} onClick={() => setIsOpen(false)}>
            {t('ok')}
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default observer(UserFeedBack);
