import { observer } from "mobx-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { LayoutContext } from "../auth";
import { getFeedbacks, readFeedback } from "../../utils/request";
import { Modal } from "antd";
import styles from "../../css/adminFeedback.module.scss";

function AdminFeedback() {
  const [feedbacks, setFeedbacks] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [activeFeedback, setActiveFeedback] = useState({});
  const { setHead } = useContext(LayoutContext);

  const getFeedbackDatas = useCallback(() => {
    getFeedbacks().then((res) => {
      setFeedbacks(res.data);
      setHead({
        title: "意见反馈",
        unread: res.data.filter((k) => !k.isRead).length,
      });
    });
  }, [setHead]);

  useEffect(() => {
    getFeedbackDatas();
  }, [getFeedbackDatas]);

  function openFeedback(item) {
    setActiveFeedback(item);
    setIsOpen(true);
  }
  function closeModal() {
    readFeedback({ id: activeFeedback.feedback_id }).then(() => {
      setIsOpen(false);
      setActiveFeedback({});
      getFeedbackDatas();
    });
  }
  return (
    <div className={styles.page}>
      <div className={styles.pageWrap}>
        {feedbacks.map((k) => (
          <div
            key={k.feedback_id}
            className={styles.feedbackItem}
            onClick={() => openFeedback(k)}
          >
            <span>{k.user_username}</span>
            <span>{k.class_name}</span>
            <span>｜</span>
            <span>{k.feedback_content}</span>
            {!k.isRead && <span className={styles.unread}></span>}
          </div>
        ))}
      </div>
      <Modal
        open={isOpen}
        closable={false}
        maskClosable={false}
        wrapClassName={styles.modalContent3}
        footer={null}
        width={609}
        onCancel={() => setIsOpen(false)}
        centered
      >
        <div>
          <p>
            <span>{activeFeedback.user_username}</span>
            <span>{activeFeedback.class_name}</span>
          </p>
          <p>{activeFeedback.feedback_content}</p>
        </div>
        <div className={styles.confirmBtn} onClick={closeModal}>
          收到
        </div>
      </Modal>
    </div>
  );
}
export default observer(AdminFeedback);
