import { observer } from "mobx-react";
import { getSurvey, answerSurvey } from "../../utils/request";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { message, Radio } from "antd";
import styles from "../../css/userSurvey.module.scss";
import { useNavigate } from "react-router";
import { LayoutContext } from "../auth";
import { useTranslation } from "react-i18next";

function UserSurvey() {
  const [survey, setSurvey] = useState({});
  const [answers, setAnswers] = useState([]);
  const navigate = useNavigate();
  const { setHead } = useContext(LayoutContext);
  const { t } = useTranslation();
  const getSurveyData = useCallback(() => {
    getSurvey().then((res) => {
      setSurvey(res.data);
      setAnswers(
        res.data.surveyQuestions.map((k) => ({
          question: k.question.id,
          answer: null,
        }))
      );
    });
  }, []);

  useEffect(() => {
    getSurveyData();
    setHead({
      needMenu: false,
    });
  }, [setHead, getSurveyData]);
  const answeredLength = useMemo(
    () => answers.filter((k) => !!k.answer)?.length,
    [answers]
  );
  const btnText = useMemo(
    () => `${answeredLength}/${survey.surveyQuestions?.length} 提交`,
    [survey, answeredLength]
  );
  const isAllChose = useMemo(() => {
    return answeredLength === survey.surveyQuestions?.length;
  }, [survey, answeredLength]);
  function onChange(e, index) {
    setAnswers((pre) => {
      const answers = [...pre];
      answers[index].answer = e.target.value;
      return answers;
    });
  }
  function onSubmit() {
    answerSurvey(answers).then(() => {
      message.success(t('submitSuccess'));
      navigate("/user", { replace: true });
    });
  }
  return (
    <div className={styles.page}>
      <div className={styles.pageWrap}>
        <div className={styles.pageTitle}>{survey.title}</div>
        <div className={styles.questions}>
          {survey.surveyQuestions?.map((k, index) => (
            <div className={styles.questionItem} key={index}>
              <p>
                {index + 1}、{k.question.title}
              </p>
              <Radio.Group
                onChange={(e) => onChange(e, index)}
                value={answers[index]?.answer}
              >
                {k.question.options.map((op, i) => (
                  <Radio value={op.id} key={i}>
                    {i + 1}{t('score')}（{op.text}）
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          ))}
        </div>
        <div
          className={`${styles.submitBtn} ${isAllChose ? styles.enable : ""}`}
          onClick={onSubmit}
        >
          {btnText}
        </div>
      </div>
    </div>
  );
}
export default observer(UserSurvey);
