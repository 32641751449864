import star from "../img/star.png";
import star2 from "../img/star2.png";
import { Rate } from "antd";
const styles = {
  display: "flex",
  alignItems: "center",
  img: {
    height: "20px",
    width: "auto",
    marginRight: "7px",
  },
};

function Stars(props) {
  return (
    <div className="stars" style={styles}>
      <Rate
        {...props}
        character={({ index = 0 }) => (
          <img
            style={styles.img}
            src={index < props.value ? star2 : star}
            key={index}
            alt="star"
          ></img>
        )}
      />
    </div>
  );
}
export default Stars;
