export const columns = [
  {
    title: "名字",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "性别",
    dataIndex: "gender",
    key: "gender",
  },
  {
    title: "班级",
    dataIndex: "className",
    key: "className",
  },
  {
    title: "邮箱",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "密码",
    dataIndex: "password",
    key: "password",
  },
  {
    title: "使用期数",
    dataIndex: "semesterName",
    key: "semesterName",
  },
  {
    title: "阅读书籍数",
    dataIndex: "bookCount",
    key: "bookCount",
  },
  {
    title: "总分/平均分",
    dataIndex: "score",
    key: "score",
    render: (_, { totalScore, averageScore }) => (
      <span>
        {totalScore}/{averageScore}
      </span>
    ),
  },
  {
    title: "实时进步总分/平均分",
    dataIndex: "progressScore",
    key: "progressScore",
    render: (_, { totalScoreIncrease, averageScoreIncrease }) => (
      <span>
        {totalScoreIncrease}/{averageScoreIncrease}
      </span>
    ),
  },
  // {
  //   title: "使用时长",
  //   dataIndex: "duration",
  //   key: "duration",
  // },
];
