export default {
    "welcome": "Welcome",
    "myCenter": "Personal Center",
    "logout": "Logout",
    "bookLanguage": "Language",
    "bookCategory": "Category",
    "feedbackPlaceholder": "Hello everyone～\nAny suggestions can be given to the teacher～\nClick here to input",
    "submit": "Submit",
    "myAccount": "My Account",
    "account": "Account",
    "password": "Password",
    "myBadge": "My Badge",
    "changePassword": "Change Password",
    "oldPassword": "Old Password",
    "newPassword": "New Password",
    "confirmChange": "Confirm Change",
    "name": "Name",
    "class": "Class",
    "login": "Login",
    "loginAccount": "Login Account",
    "ok": "OK",
    "vote": "Vote",
    "challenge": "Challenge",
    "allBooks": "All Books",
    "finishedBooks": "Finished {{count}} books",
    "myTotalScore": "Total Score",
    "averageScore": "Average Score",
    "answerScore": "Answer Score",
    "reflectionScore": "Reflection Score",
    "totalRanking": "Total Ranking",
    "progressRanking": "Progress Ranking",
    "ranking": "Ranking",
    "name": "Name",
    "class": "Class",
    "bookCount": "Book Count",
    "totalScoreIncrease": "Total Score Increase",
    
    hint: {
        "pleaseInputOldPassword": "Please input old password",
        "pleaseInputNewPassword": "Please input new password",
        "changePasswordSuccess": "Change password success",
        "pleaseInputFeedbackContent": "Please input feedback content",
        "submitSuccess": "Submit success",
        "thankYouForFeedback": "Thank you for feedback",
        "teacherWillReview": "Teacher will review",
        "noResult": "No result",
        "searchResult": "Search result {{count}} books",
        "searchPlaceholder": "Search books",
        "pleaseAnswerCurrentQuestion": "Please answer the current question",
    },
    userMenu: {
        0: "Challenge",
        1: "My Grade",
        2: "My Ranking",
        3: "Personal Center",
        4: "Feedback"
    },
    "goRead": "Go Read",
    "challengeTimes": "Challenge 3 times (calculate the highest score)",
    "challenge2Times": "Second challenge at 90% off",
    "challenge3Times": "Third challenge at 80% off",
    "startChallenge": "Start Challenge",
    "nextQuestion": "Next",
    "remark": "Remark",
    "congratulations": "Congratulations!",
    "congratulationsContent": "Complete the challenge of《{{bookTitle}}》",
    "yourScore": "Your score is",
    "score": "",
    "challengeAgain": "Challenge",
    "reflectionScoreIs": "Reflection Score is",
    "totalScore": "Total Score",
    "waitingForScore": "Waiting for teacher score",
    "writeReflection": "Write reading reflection",
    "reflectionTitlePlaceholder": "No more than 20 words",
    "reflectionContentPlaceholder": "No more than 1000 words",
    "markRatingLabel": "Rate the book",
    "submitReflection": "Submit reflection",
    "winGoldBadge": "Win Gold Badge",
    "winSilverBadge": "Win Silver Badge",
    "winCopperBadge": "Win Copper Badge",
    "loginLeftContent": "Students can not only immerse themselves in the exciting stories and knowledge ocean, but also enjoy the reading fun while participating in interactive question and answer challenges.",
    "submitSuccess": "Submit success",
}
