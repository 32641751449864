import { observer } from "mobx-react";
import { getReflections } from "../../utils/request";
import { useCallback, useContext, useEffect, useState } from "react";
import styles from "../../css/adminReflections.module.scss";
import { useNavigate } from "react-router";
import { LayoutContext } from "../auth";

function AdminReflections() {
  const [ratedReflections, setRatedReflections] = useState([]);
  const [ratedCount, setRatedCount] = useState(0);
  const [unratedReflections, setUnratedReflections] = useState([]);
  const [unratedCount, setUnratedCount] = useState(0);

  const navigate = useNavigate();
  const { setHead } = useContext(LayoutContext);
  const getAllReflections = useCallback(() => {
    getReflections().then((res) => {
      setRatedReflections(res.data.rated.reflections);
      setUnratedReflections(res.data.unrated.reflections);
      setRatedCount(res.data.rated.count);
      setUnratedCount(res.data.unrated.count);
    });
  }, []);

  useEffect(() => {
    getAllReflections();
    setHead({
      title: "读后感打分",
    });
  }, [getAllReflections, setHead]);
  function onItemClick(id) {
    navigate(`/admin/reflectiondetail/${id}`);
  }
  return (
    <div className={styles.page}>
      <div className={styles.unrated}>
        <p className={styles.title}>
          未打分<span>{unratedCount}</span>
        </p>
        <div className={styles.reflections}>
          {unratedReflections.map((item) => (
            <div
              key={item.id}
              className={styles.reflectionItem}
              onClick={() => onItemClick(item.id)}
            >
              {item.title}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.rated}>
        <p className={styles.title}>
          已打分<span>{ratedCount}</span>
        </p>
        <div className={styles.reflections}>
          {ratedReflections.map((item) => (
            <div
              key={item.id}
              className={styles.reflectionItem}
              onClick={() => onItemClick(item.id)}
            >
              {item.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default observer(AdminReflections);
