import Loading from './Loading';
import styles from "../css/pageHead.module.scss";
import userStore from '../store/userStore'
import { observer } from "mobx-react";
import { useEffect, useMemo } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router";

function AdminHead({ activeKey, title = "", unread = 0 }) {
  const normalTitle = useMemo(() => {
    if (title) {
      return `悦读探险家 后台管理系统 ｜ ${title}`;
    } else {
      return "悦读探险家 后台管理系统";
    }
  }, [title]);

  useEffect(() => {
    userStore.refresh();
  }, [activeKey]);

  const navigate = useNavigate();
  const userProfile = userStore.profile;
  if (!userProfile || userStore.loading) return <Loading />;

  function logOut() {
    localStorage.removeItem("token");
    navigate("/adminlogin");
  }
  function goHome() {
    navigate("/admin");
  }
  return (
    <div className={styles.pageHead}>
      <div onClick={goHome} className={styles.pageTitle}>
        <span>{normalTitle}</span>
        {unread > 0 && <span className={styles.unread}>{unread}</span>}
      </div>
      <div>
        <span>欢迎，{userProfile.username}</span>
        <Button className={styles.logout} onClick={logOut}>
          登出
        </Button>
      </div>
    </div>
  );
}
export default observer(AdminHead)