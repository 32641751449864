import { observer } from "mobx-react";
import { Input, Spin } from "antd";
import styles from "../../css/userHome.module.scss";
import searchIcon from "../../img/searchIcon.png";
import noResultImg from "../../img/noresult.png";
import Stars from "../../components/stars";
import { getCategories, getBooks } from "../../utils/request";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { LayoutContext } from "../auth";
import { useTranslation } from "react-i18next";

function SearchIcon() {
  return (
    <div className={styles.searchIcon}>
      <img src={searchIcon} alt="search"></img>
    </div>
  );
}

function BookList({ books, goChallenge }) {
  const { t } = useTranslation()
  if (books.length === 0) {
    return (
      <div className={styles.noResult}>
        <img src={noResultImg} alt="noResult"></img>
        <p>{t('hint.noResult')}</p>
      </div>
    );
  }
  return (
    <div className={styles.books}>
      
      {books.map((item, i) => (
        <div className={styles.bookItem} key={i}>
          <div className={styles.bookCover}>
            <img src={item.cover} alt="cover"></img>
          </div>
          <div className={styles.itemInfo}>
            <p className={styles.itemName}>《{item.title}》</p>
            <div style={{ height: "24px" }}>
              <Stars
                value={item.averageMark}
                count={item.averageMark}
                disabled
              ></Stars>
            </div>
            <p className={styles.itemVote}>{t('vote')}：{item.reflectionCount}</p>
            <div className={styles.itemBtn} onClick={() => goChallenge(item)}>
              {t('challenge')}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

function UserHome() {
  const [languages, setLanguages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [activeLanguage, setActiveLanguage] = useState(-1);
  const [activeSubCategory, setActiveSubCategory] = useState(0);
  const [keyWord, setKeyWord] = useState("");
  const [books, setBooks] = useState([]);
  const [isSearch, setIsSearch] = useState(false); // 是否是搜索模式
  const { setHead } = useContext(LayoutContext);
  const [loading ,setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getAllCategories = useCallback(() => {
    
    getCategories().then((res) => {
      setCategories(res.data.categories);
      setLanguages([
        {
          name: t('allBooks'),
          id: -1,
        },
        ...res.data.categories,
      ]);
      updateSubCategories(getAllSubCategories(res.data.categories));
      getAllBooks(null, "", null);
    });
  }, [t]);

  useEffect(() => {
    getAllCategories();
    setHead({
      needMenu: true,
    });
  }, [setHead, getAllCategories]);

  function getAllSubCategories(categories) {
    return categories.map((k) => k.subcategories).flat();
  }

  function updateSubCategories(subCategories) {
    const baseCategories = [
      {
        name: t('allBooks'),
      },
    ];
    setSubCategories(baseCategories.concat(subCategories));
  }

  function onLanguageChange(languageItem, index) {
    setActiveLanguage(languageItem.id);
    const newIdx = index === 0 ? 0 : index - 1;
    const newSubCategories =
      index === 0
        ? getAllSubCategories(categories)
        : categories[newIdx].subcategories;
    updateSubCategories(newSubCategories);
    setActiveSubCategory(0);
    const categoryId = index === 0 ? null : languageItem.id;
    getAllBooks(null, keyWord, categoryId);
  }

  function onSubCategoryChange(index) {
    setActiveSubCategory(index);
    const subCategoryId = index === 0 ? null : subCategories[index].id;
    getAllBooks(subCategoryId, keyWord, activeLanguage);
  }

  function getAllBooks(subCategoryId, keyword, categoryId) {
    const category = categoryId === -1 ? null : categoryId;
    setLoading(true)
    getBooks({
      subCategoryId,
      keyword,
      categoryId: category,
    }).then((res) => {
      setBooks(res.data);
      setLoading(false)
    });
  }

  function onKeyWordChange(e) {
    setKeyWord(e.target.value);
    if (!e.target.value) {
      setIsSearch(false);
    }
  }

  function doSearch() {
    const subCategoryId =
      activeSubCategory === 0 ? null : subCategories[activeSubCategory].id;
    getAllBooks(subCategoryId, keyWord, activeLanguage);
    setIsSearch(!!keyWord ? true : false);
  }

  function goChallenge(bookItem) {
    navigate(`/user/challengeTransfer/${bookItem.id}`);
  }

  return (
    <div className={styles.page}>
      <div className={styles.bookWrap}>
        <div className={styles.bookLanguagesWrap}>
          <div className={styles.languageLeft}>
            <p>{t('bookLanguage')}</p>
            <div className={styles.bookLanguages}>
              {languages.map((k, i) => (
                <div
                  className={`${styles.languageItem} ${
                    activeLanguage === k.id ? styles.activeLanguageItem : ""
                  }`}
                  onClick={() => onLanguageChange(k, i)}
                  key={i}
                >
                  {k.name}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.bookSearch}>
            <Input
              placeholder={t('hint.searchPlaceholder')}
              value={keyWord}
              onChange={onKeyWordChange}
              onPressEnter={doSearch}
              prefix={<SearchIcon />}
            />
          </div>
        </div>
        <div className={styles.bookCategoriesWrap}>
          <p>{t('bookCategory')}</p>
          <div className={styles.bookCategories}>
            {subCategories.map((k, i) => (
              <div
                className={`${styles.categoryItem} ${
                  activeSubCategory === i ? styles.activeCategoryItem : ""
                }`}
                key={i}
                onClick={() => onSubCategoryChange(i)}
              >
                {k.name}
              </div>
            ))}
          </div>
        </div>
        { loading ? (
          <Spin spinning={true} style={{marginTop: '100px'}}></Spin>
        ) : <BookList books={books} goChallenge={goChallenge}></BookList>}

        <div className={styles.searchMsg}>
          {isSearch && <p>{t('hint.searchResult', {count: books.length})}</p>}
        </div>
      </div>
    </div>
  );
}


export default observer(UserHome);
