import React, { createContext, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useMatches } from "react-router-dom";
import userStore from "../../store/userStore";
import UserHead from "../../components/UserHead";
import AdminHead from "../../components/AdminHead";

export const LayoutContext = createContext();

const copyrightStyle = {
  position: "absolute",
  bottom: "6px",
  left: "50%",
  color: "#fff",
  fontSize: "14px",
  transform: "translateX(-50%)",
};

function Child({ admin }) {
  const matches = useMatches();
  const currentRoute = matches[matches.length - 1];
  const routeOption = currentRoute.data || {};
  const [head, setHead] = useState({ needMenu: true });
  const needHead = Object.hasOwn(routeOption, "needHead")
    ? routeOption.needHead
    : true;
  return (
    <LayoutContext.Provider value={{ setHead }}>
      <div
        style={{
          background: admin ? "#f7f7f7" : "#B6E55C",
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        {!admin && needHead && <UserHead {...head}></UserHead>}
        {admin && needHead && <AdminHead {...head}></AdminHead>}
        <div style={{ flex: 1, height: 0 }}>
          <Outlet />
        </div>
        <div style={copyrightStyle}>粤ICP备2024306820号-1</div>
      </div>
    </LayoutContext.Provider>
  );
}

export const PrivateRoute = ({ admin }) => {
  const isPermission = admin ? userStore.isAdmin : userStore.isAuth;
  return isPermission ? <Child admin={admin} /> : <Navigate to="/login" />;
};
