import { observer } from "mobx-react";
import styles from "../../css/adminHome.module.scss";
import questionImg from "../../img/question.png";
import collectImg from "../../img/collect.png";
import reflectionImg from "../../img/reflection.png";
import surveyImg from "../../img/survey.png";
import feedbackImg from "../../img/feedback.png";
import { useNavigate } from "react-router";
import { getUnreadFeedbacks, getUnreadReflections } from "../../utils/request";
import { useCallback, useEffect, useState } from "react";
function AdminHome() {
  const navigate = useNavigate();
  const [feedbackUnread, setFeedbackUnread] = useState(0);
  const [reflectionUnread, setReflectionUnread] = useState(0);
  const getUnreadReflection = useCallback(() => {
    getUnreadReflections().then((res) => {
      setReflectionUnread(res.data);
    });
  }, []);
  const getUnreadFeedback = useCallback(() => {
    getUnreadFeedbacks().then((res) => {
      setFeedbackUnread(res.data);
    });
  }, []);

  useEffect(() => {
    getUnreadFeedback();
    getUnreadReflection();
  }, [getUnreadFeedback, getUnreadReflection]);
  return (
    <div className={styles.page}>
      <div className={styles.modules}>
        <div
          className={styles.moduleItem}
          onClick={() => navigate("/admin/book")}
        >
          <div>
            <img src={questionImg} alt="question"></img>
          </div>
          <p>题库</p>
        </div>
        <div
          className={styles.moduleItem}
          onClick={() => navigate("/admin/collect")}
        >
          <div>
            <img src={collectImg} alt="collect"></img>
          </div>
          <p>数据收集</p>
        </div>
        <div
          className={styles.moduleItem}
          onClick={() => navigate("/admin/reflections")}
        >
          <div>
            <img src={reflectionImg} alt="reflection"></img>
          </div>
          <p>读后感打分</p>
          {!!reflectionUnread && (
            <span className={styles.unread}>{reflectionUnread}</span>
          )}
        </div>
        <div
          className={styles.moduleItem}
          onClick={() => navigate("/admin/survey")}
        >
          <div>
            <img src={surveyImg} alt="suvey"></img>
          </div>
          <p>问卷调查</p>
        </div>
        <div
          className={styles.moduleItem}
          onClick={() => navigate("/admin/feedback")}
        >
          <div>
            <img src={feedbackImg} alt="feedback"></img>
          </div>
          <p>意见反馈</p>
          {!!feedbackUnread && (
            <span className={styles.unread}>{feedbackUnread}</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default observer(AdminHome);
