import React from 'react';
import ReactDOM from "react-dom/client";
import { RouterProvider } from 'react-router';
import router from './router'
import zh from './locale/zh';
import en from './locale/en';
import { initReactI18next } from 'react-i18next';



import i18n from "i18next";
i18n.use(initReactI18next).init({
 resources: {
  zh: {
    translation: zh,
  },
  en: {
    translation: en,
  },
 }, // Where we're gonna put translations' files
 fallbackLng: "zh",     // Set the initial language of the App
 lng: localStorage.getItem("i18nextLng") || "zh" 
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

