import styles from "../../css/userPerson.module.scss";
import goldImg from "../../img/gold.png";
import silverImg from "../../img/silver.png";
import copperImg from "../../img/copper.png";
import userStore from "../../store/userStore";
import { Modal, Input, message } from "antd";
import { getRatings, modifyPassword } from "../../utils/request";
import { observer } from "mobx-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { LayoutContext } from "../auth";
import { useTranslation } from "react-i18next";

function UserPerson() {
  const [ratings, setRatings] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [passWord, setPassWord] = useState("");
  const [newPassWord, setNewPassWord] = useState("");
  const { setHead } = useContext(LayoutContext);
  const { t } = useTranslation()

  const getRatingStatistics = useCallback(() => {
    getRatings().then((res) => {
      setRatings(res.data);
    });
  }, []);

  useEffect(() => {
    getRatingStatistics();
    setHead({
      needMenu: true,
    });
  }, [setHead]);
  
  const profile = userStore.profile;

  function onChangePassword() {
    if (!passWord || !newPassWord) {
      message.warning(!passWord ? t('hint.pleaseInputOldPassword') : t('hint.pleaseInputNewPassword'));
      return;
    }
    modifyPassword({
      currentPassword: passWord,
      newPassword: newPassWord,
    }).then((res) => {
      setIsOpen(false);
      message.success(t('hint.changePasswordSuccess'));
    });
  }

  return (
    <div className={styles.page}>
      <div className={styles.pageWrap}>
        <div className={styles.pageContent}>
          <div className={styles.contentLeft}>
            <div className={styles.contentInfo}>
              <p className={styles.leftTitle}>- {t('account')} -</p>
              <div className={styles.user}>
                <p>
                  <span>{t('name')}</span>
                  <span>{profile?.username}</span>
                </p>
                <p>
                  <span>{t('class')}</span>
                  <span>{profile?.class?.name}</span>
                </p>
              </div>
            </div>
            <div className={styles.changeBtn} onClick={() => setIsOpen(true)}>
              {t('changePassword')}
            </div>
          </div>
          <div className={styles.contentRight}>
            <p className={styles.rightTitle}>- {t('myBadge')} -</p>
            <div className={styles.badgesWrap}>
              <div className={styles.badgeItem}>
                <img src={goldImg} alt="gold"></img>
                <span>{ratings.gold}</span>
              </div>
              <div className={styles.badgeItem}>
                <img src={silverImg} alt="silver"></img>
                <span>{ratings.silver}</span>
              </div>
              <div className={styles.badgeItem}>
                <img src={copperImg} alt="copper"></img>
                <span>{ratings.copper}</span>
              </div>
            </div>
            <div className={styles.decoration}></div>
          </div>
        </div>
        <div className={styles.bg1}></div>
        <div className={styles.bg2}></div>
        <div className={styles.pencil}></div>
      </div>
      <Modal
        open={isOpen}
        wrapClassName={styles.modalContent}
        footer={null}
        width={800}
        onCancel={() => setIsOpen(false)}
        centered
      >
        <p className="title">- {t('changePassword')} -</p>
        <Input
          placeholder={t('oldPassword')}
          type="password"
          className={styles.accountInput}
          value={passWord}
          onChange={(e) => setPassWord(e.target.value)}
        ></Input>
        <Input
          placeholder={t('newPassword')}
          className={styles.accountInput}
          type="password"
          value={newPassWord}
          onChange={(e) => setNewPassWord(e.target.value)}
        ></Input>
        <div className="confirmBtn" onClick={onChangePassword}>
          {t('confirmChange')}
        </div>
        <div className="decoration"></div>
      </Modal>
    </div>
  );
}
export default observer(UserPerson);
