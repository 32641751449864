import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Input, Radio, Form, Select, Button, message } from "antd";
import styles from "../../../css/adminBookCreate.module.scss";
import { generateQuestion } from "../../../utils/request";

const QuestionItem = forwardRef(({ item, bookInfoRef, content }, ref) => {
  const [form] = Form.useForm();
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false)

  const questionTypeOptions = [
    {
      value: 'Information Retrieval 资讯检索'
    },{
      value: 'Inferences 推论信息'
    },{
      value: 'Interpretation 解释和整合'
    },{
      value: 'Evaluation评估'
    }
  ]
  const [questionType, setQuestionType] = useState('');

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const handleGenQuestions = async () => {
    console.log(bookInfoRef.current.bookInfo, content);
    
    if (!bookInfoRef.current.bookInfo.title) {
      message.warning('请先填写书名');
      return;
    }
    if(!questionType) {
      message.warning('请选择题目类型');
      return;
    }
    // 请求AI生成题目
    setLoading(true);
    const result = await generateQuestion({
      title: bookInfoRef.current.bookInfo.title,
      category: bookInfoRef.current.bookInfo.category,
      content: content,
      questionType: questionType
    })
    setLoading(false);
    if(result) {
      form.setFieldsValue({
        title: result.data.question,
        optionA: result.data.answerList[0],
        optionB: result.data.answerList[1],
        optionC: result.data.answerList[2],
        optionD: result.data.answerList[3],
        remark: result.data.explanation
      });
      setValue(result.data.correctAnswerIndex);
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      title: item.title,
      remark: item.remark,
      optionA: item.options[0].text,
      optionB: item.options[1].text,
      optionC: item.options[2].text,
      optionD: item.options[3].text,
    });
    setValue(item.correctValue);
  }, [item, form]);

  useImperativeHandle(ref, () => ({
    form,
    correctValue: value,
    setCorrect: (val) => setValue(val),
    setFields: (values) => form.setFieldsValue(values),
    validate: () => form.validateFields(),
    getValues: () => form.getFieldsValue(true),
  }));

  return (
    <div>
        <Select
          className={styles.select}
          style={{ width: "300px" }}
          placeholder="AI智能出题选择类型"
          onChange={setQuestionType}
          options={questionTypeOptions}
        />
        <Button type='primary' loading={loading} style={{ marginLeft: '10px' }} onClick={handleGenQuestions}>AI生成</Button>

      <Form
        ref={ref}
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 18 }}
      >
        <Radio.Group noStyle onChange={onChange} value={value}>
          <div className={styles.itemContent}>
            <Form.Item name="title" label="填写题目" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </div>
          <div className={styles.itemContent}>
            <Form.Item name="optionA" label="选项A" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Radio value={0} onChange={(e) => e.stopPropagation()}></Radio>
          </div>
          <div className={styles.itemContent}>
            <Form.Item name="optionB" label="选项B" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Radio value={1} onChange={(e) => e.stopPropagation()}></Radio>
          </div>
          <div className={styles.itemContent}>
            <Form.Item name="optionC" label="选项C" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Radio value={2} onChange={(e) => e.stopPropagation()}></Radio>
          </div>
          <div className={styles.itemContent}>
            <Form.Item name="optionD" label="选项D" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Radio value={3} onChange={(e) => e.stopPropagation()}></Radio>
          </div>
          <div className={styles.itemContent}>
            <Form.Item name="remark" label="备注">
              <Input.TextArea  rows={4} />
            </Form.Item>
          </div>
        </Radio.Group>
      </Form>
    </div>
  );
});
export default QuestionItem;
