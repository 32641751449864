import styles from "../../css/UserChallenge.module.scss";
import write from "../../img/write.png";
import { observer } from "mobx-react";
import { getBook, answerBook, getBookChallenge } from "../../utils/request";
import { useNavigate, useParams } from "react-router";
import { useState, useEffect, useContext, useCallback } from "react";
import { message, Modal, Spin } from "antd";
import right from "../../img/right.png";
import error from "../../img/error.png";
import { LayoutContext } from "../auth";
import { useTranslation } from "react-i18next";
const optionSerials = ["A", "B", "C", "D"];

function TabChild({ question, isLast, index }) {
  if (isLast) return <img src={write} alt="write"></img>;
  if (question.isCorrect === -1) return `Q${index + 1}`;
  return question.isCorrect ? (
    <img src={right} alt="right"></img>
  ) : (
    <img src={error} alt="error"></img>
  );
}

function UserChallenge() {
  const [questions, setQuestions] = useState([]);
  const [active, setActive] = useState(0);
  const [activeQuestion, setActiveQuestion] = useState({});
  const [chosed, setChosed] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const { id, challengeId } = useParams();
  const { setHead } = useContext(LayoutContext);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getBookInfo = useCallback(
    (activeId) => {
      return getBook(id).then((res) => {
        const questions = res.data.questions.map((k) => ({
          ...k,
          isCorrect: -1,
        }));
        setQuestions([...questions, ""]);
        setActiveQuestion(questions[activeId]);
      });
    },
    [id]
  );

  const getChallengeInfo = useCallback(() => {
    getBookChallenge(challengeId).then((res) => {
      setActive(res.data.progress);
      getBookInfo(res.data.progress).then(() => {
        setQuestions((preQuestions) => {
          return preQuestions.map((k, i) => {
            if (!k) return k;
            const currentAnswer = res.data.answers[i];
            const isCorrect =
              currentAnswer && Object.hasOwn(currentAnswer, "isCorrect")
                ? currentAnswer.isCorrect
                : k?.isCorrect;
            return {
              ...k,
              isCorrect,
            };
          });
        });
      });
    });
  }, [challengeId, getBookInfo]);

  useEffect(() => {
    getChallengeInfo();
    setHead({
      needMenu: false,
    });
  }, [getChallengeInfo, setHead]);

  function onChoseOption(id) {
    setChosed({
      question: activeQuestion.id,
      answer: id,
      index: active,
      challengeId: challengeId,
      isLast: active === questions.length - 2,
    });
  }

  function changeActiveQuestion() {
    setIsOpen(false);
    setTimeout(() => {
      setChosed({});
      setActive((pre) => {
        const newActive = pre + 1;
        setActiveQuestion(questions[newActive]);
        return newActive;
      });
    }, 100);
  }
  function goNext() {
    if (!chosed.answer) {
      message.warning(t('hint.pleaseAnswerCurrentQuestion'));
      return;
    }
    setLoading(true);
    answerBook(chosed).then((res) => {
      setQuestions((pre) => {
        pre[active].isCorrect = res.data;
        return pre;
      });
      if (chosed.isLast) {
        navigate(`/user/challengefinish/${id}/${challengeId}`, {
          replace: true,
        });
        return;
      }
      if (activeQuestion.remark) {
        setIsOpen(true);
        return;
      }
      changeActiveQuestion();
    }).finally(() => {
      setLoading(false);
    });
  }

  return (
    <div className={styles.page}>
       <Spin spinning={loading}>
          <div className={styles.pageContent}>
            <div className={styles.questionTabs}>
              {questions.map((k, i) => (
                <div
                  className={`${styles.questionTabItem} ${
                    active >= i ? styles.questionTabItemActive : ""
                  }`}
                  key={i}
                >
                  <TabChild
                    question={k}
                    isLast={i === questions.length - 1}
                    index={i}
                  ></TabChild>
                </div>
              ))}
            </div>
            <div className={styles.questionContent}>
              <p className={styles.questionTitle}>{activeQuestion.title}</p>
              {activeQuestion.options &&
                activeQuestion.options.map((item, i) => (
                  <div
                    className={`${styles.questionOption} ${
                      chosed.answer === item.id ? styles.questionOptionActive : ""
                    }`}
                    key={item.id}
                    onClick={() => onChoseOption(item.id)}
                  >
                    {optionSerials[i]}. {item.text}
                  </div>
                ))}
              <div className={styles.nextBtn} onClick={goNext}>
              {t('nextQuestion')}
              </div>
            </div>
          </div>
        </Spin>
        <Modal
          open={isOpen}
          footer={null}
          closable={false}
          wrapClassName={styles.modalContent3}
          maskClosable={false}
          width={945}
          onCancel={() => setIsOpen(false)}
          style={{ top: "228px" }}
        >
          <div>
            <p className={styles.feedMsg}>{t('remark')}</p>
            <p className={styles.feedMsg}>{activeQuestion.remark}</p>
            <div className={styles.feedBtn} onClick={changeActiveQuestion}>
              {t('nextQuestion')}
            </div>
          </div>
        </Modal>
    </div>
  );
}
export default observer(UserChallenge);
