import { memo, useCallback, useMemo } from "react";
import { message, Tabs } from "antd";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import QuestionItem from "./questionItem";

const defaultQuestion = {
  title: "",
  remark: "",
  options: [{ text: "" }, { text: "" }, { text: "" }, { text: "" }],
  correctValue: "",
};

const RenderQuestions = memo(
  forwardRef(({bookInfoRef, content}, ref) => {

    const [questions, setQuestions] = useState(
      Array.from({ length: 12 }, () => ({ ...defaultQuestion }))
    );

    const questionRefs = useRef([]);
    
    const [activeKey, setActiveKey] = useState(0);

    const formatTabItems = useCallback(() => {
      return questions.map((item, i) => {
        return {
          label: i < 8 ? `Q${i + 1}*` : `Q${i + 1}`,
          key: i,
          children: (
            <QuestionItem
              bookInfoRef={bookInfoRef}
              item={item}
              content={content}
              ref={(el) => (questionRefs.current[i] = el)}
            />
          ),
        };
      });
    }, [questions]);
    
    const tabItems = useMemo(
      () => formatTabItems(questions),
      [formatTabItems, questions]
    );
    function normalize(options) {
      return options.map((k) => ({
        ...k,
        name: k.name || k.text,
      }));
    }
    function isInvalid(val) {
      return val === undefined || val === null || val === "";
    }
    async function onTabChange(key) {
      const fieldValues = questionRefs.current[activeKey]?.getValues();
      if (fieldValues && Object.values(fieldValues).some((k) => !!k)) {
        await questionRefs.current[activeKey]?.validate();
      }
      if (isInvalid(questionRefs.current[activeKey].correctValue)) {
        message.warning("请选择正确答案");
        return;
      }
      setActiveKey(key);
    }
    async function validateQuestions() {
      try {
        for (let i = 0; i < questionRefs.current.length; i++) {
          const fieldValues = questionRefs.current[i]?.getValues();
          if (fieldValues && Object.values(fieldValues).some((k) => !!k)) {
            await questionRefs.current[i]?.validate();
          }
        }
        return questions
          .map((a, i) => ({
            correctValue:
              a.correctValue || questionRefs.current[i]?.correctValue,
            id: a.id,
            title: a.title,
            remark: a.remark,
            options: normalize(a.options),
            ...(questionRefs.current[i]?.getValues() || {}),
          }))
          .filter((k) => !!k.title);
      } catch (error) {
        console.log(error);
      }
    }
    function setQuestionsData(updates) {
      setQuestions(
        questions.map((item, i) => ({
          ...item,
          ...updates[i],
        }))
      );
    }
    useImperativeHandle(ref, () => ({
      setQuestionsData,
      validateQuestions,
    }));

    return (
      <Tabs
        activeKey={activeKey}
        tabPosition="left"
        style={{ height: "100%" }}
        items={tabItems}
        onChange={onTabChange}
      />
    );
  })
);
export default RenderQuestions;
