import { observer } from "mobx-react";
import styles from "../css/userHead.module.scss";
import userLogo from "../img/userLogo.png";

import userMenu1 from "../img/1.png";
import userMenu2 from "../img/2.png";
import userMenu3 from "../img/3.png";
import userMenu4 from "../img/4.png";
import userMenu5 from "../img/5.png";

import userMenu1Active from "../img/01.png";
import userMenu2Active from "../img/02.png";
import userMenu3Active from "../img/03.png";
import userMenu4Active from "../img/04.png";
import userMenu5Active from "../img/05.png";

import { useCallback, useEffect, useState } from "react";
import userStore from "../store/userStore";
import { useLocation, useNavigate } from "react-router";
import { Dropdown, Popover } from "antd";
import { GlobalOutlined, CaretDownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";


const Menus = [
  {
    default: userMenu1,
    active: userMenu1Active,
    route: "/user",
  },
  {
    default: userMenu2,
    active: userMenu2Active,
    route: "/user/grade",
  },
  {
    default: userMenu3,
    active: userMenu3Active,
    route: "/user/trend",
  },
  {
    default: userMenu4,
    active: userMenu4Active,
    route: "/user/person",
  },
  {
    default: userMenu5,
    active: userMenu5Active,
    route: "/user/feedback",
  },
];


function UserHead({ needMenu = true }) {
  const [active, setActive] = useState(0);
  const userProfile = userStore.profile;
  const navigate = useNavigate();
  const location = useLocation();

  const [language, setLanguage] = useState(0);
  const { t } = useTranslation();
  
  useEffect(() => {
    setLanguage(i18n.language === 'zh' ? 0 : 1);
  }, [i18n.language]);

  const items = [
    {
      key: '0',
      label: '🇨🇳 中文',
      onClick: () => {
        i18n.changeLanguage('zh');
        localStorage.setItem("i18nextLng", "zh");
      },
    },
    {
      key: '1', 
      label: '🇺🇸 EN',
      onClick: () => {
        i18n.changeLanguage('en');
        localStorage.setItem("i18nextLng", "en");
      },
    }
  ]

  
  const restoreActive = useCallback(() => {
    const index = Menus.findIndex((k) => k.route === location.pathname);
    setActive(index);
  }, [location]);

  useEffect(() => {
    restoreActive();
    userStore.refresh();
  }, [restoreActive]);

  function onMenuChange(item, index) {
    if (location.pathname === item.route) return;
    setActive(index);
    navigate(item.route);
  }
  function onLogout() {
    localStorage.removeItem("token");
    navigate("/login");
  }

  function handleHome() {
    navigate("/user");
  }
  return (
    <div className={styles.userHead}>
      <div className={styles.headLeft}>
        <img className={styles.userLogo} src={userLogo} alt="logo" onClick={handleHome}></img>
        {needMenu && (
          <div className={styles.userMenus}>
            {Menus.map((k, i) => (
              <div  onClick={() => onMenuChange(k, i)} className={[styles.menuItem, active === i ? styles.activeMenuItem : ''].join(' ')} key={i}>
                <img
                  src={active === i ? k.active : k.default}
                  alt="menu"
                ></img>
                <div className={styles.menuName}>{t(`userMenu.${i}`)}</div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={styles.headRight}>

        <Dropdown menu={{ items }}>
          <div className={styles.language}>
            {
              items[language].label
            }
            <GlobalOutlined className={styles.languageIcon} />
          </div>
        </Dropdown>
      
        <Popover
            content={
              <div>
                <Link to='/user/person'><div className={styles.myAccount}>{t('myCenter')}</div></Link>
                <div className={styles.divider} />
                <div className={styles.logOut} onClick={onLogout}>{t('logout')}</div>
              </div>
            }
            arrow={false}
            placement="bottomLeft"
            overlayClassName={styles.pop}
          >

          <div className={styles.userInfo}>
            <div className={styles.userName}>{t('welcome')}，{ userProfile?.username }</div>
            <CaretDownOutlined />
          </div>

        </Popover>

      </div>
    </div>
  );
}
export default observer(UserHead);
