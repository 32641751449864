import { observer } from "mobx-react";
import Stars from "../../components/stars";
import { getGrades } from "../../utils/request";
import styles from "../../css/userGrade.module.scss";
import { useCallback, useContext, useEffect, useState } from "react";
import { LayoutContext } from "../auth";
import noResultImg from "../../img/noresult.png";
import { useTranslation } from "react-i18next";

function UserGrade() {
  const [books, setBooks] = useState([]);
  const [total, setTotal] = useState(0);
  const [average, setAverage] = useState(0);
  const { setHead } = useContext(LayoutContext);
  const { t } = useTranslation()

  const getGradesData = useCallback(() => {
    getGrades().then((res) => {
      setBooks(res.data.books);
      setTotal(res.data.total);
      setAverage(res.data.average);
    });
  }, []);

  useEffect(() => {
    getGradesData();
    setHead({
      needMenu: true,
    });
  }, [setHead, getGradesData]);

  return (
    <div className={styles.page}>
      <div className={styles.pageWrap}>
        <div className={styles.pageTitle}>
          <span>{t('finishedBooks', {count: books.length})}</span>
          <span>{t('myTotalScore')} {total}</span>
          <span>{t('averageScore')} {average}</span>
        </div>

        {
          books.length === 0 && (
            <div className={styles.noResult}>
              <img src={noResultImg} alt="noResult"></img>
              <p>{t('hint.noResult')}</p>
            </div>
          )
        }
        <div className={styles.books}>
          {books.map(({ book, id, rating, vote, reflection }) => (
            <div className={styles.bookItem} key={id}>
              <div className={styles.bookCover}>
                <img src={book.cover} alt="cover"></img>
              </div>
              <div className={styles.itemInfo}>
                <p className={styles.itemName}>《{book.title}》</p>
                <p>{t('answerScore')}: {rating}</p>
                <p>{t('reflectionScore')}: {reflection?.rating || '*'}</p>
                <p>{t('myTotalScore')}: {rating + (reflection?.rating || 0)}</p>
                <Stars
                  value={reflection?.mark}
                  count={reflection?.mark}
                  disabled
                ></Stars>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default observer(UserGrade);
