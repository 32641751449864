import { observer } from "mobx-react";
import BookInfo from "./components/bookInfo";
import RenderQuestions from "./components/questions";
import { downloadBlob } from "../../utils/utils";
import { DownloadOutlined } from "@ant-design/icons";
import { Upload, Button, message, Modal, Input } from "antd";
import { submitBook, downloadQuestionTemplate } from "../../utils/request";
import styles from "../../css/adminBookCreate.module.scss";
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import AIIcon from "../../img/ai-iocn.png";

function AdminBookCreate() {
  const { id } = useParams();
  const navigate = useNavigate();
  const questionsRef = useRef();
  const bookInfoRef = useRef();
  const token = localStorage.getItem("token");
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState("");

  const uploadOptions = {
    name: "file",
    action: "/v1/upload/books",
    showUploadList: false,
    data: {
      type: "books",
    },
    headers: {
      authorization: `Bearer ${token}`,
    },
    beforeUpload(file) {
      const extName = file.name.split(".").pop().toLowerCase();
      const types = ["zip", "xls", "xlsx"];
      if (!types.includes(extName)) {
        message.error(`You must upload xls/xlsx`);
        return false;
      }
      return true;
    },
    onChange(info) {
      if (info.file.status === "done") {
        const responseData = info.file.response.data;
        processResult(responseData);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  function processResult(result) {
    if (result.length > 1) {
      message.success("导入成功");
      return;
    }
    const { bookInfo, questionInfo } = result[0];
    if (bookInfo) {
      bookInfoRef.current.updateBook(bookInfo);
    }
    if (questionInfo) {
      questionsRef.current.setQuestionsData(
        questionInfo.map((questionItem, i) => {
          const correctValue = questionItem.options.findIndex(
            (k) => k.isCorrect
          );
          return {
            index: i,
            id: questionItem.id,
            title: questionItem.title,
            remark: questionItem.remark,
            options: questionItem.options,
            correctValue: correctValue,
          };
        })
      );
    }
  }

  function getOptions(item) {
    return item.options.map((k, i) => ({
      ...k,
      isCorrect: item.correctValue === i,
    }));
  }
  function downloadTemp(e) {
    e.stopPropagation();
    downloadQuestionTemplate().then((res) => {
      downloadBlob(res, "题库导入模板");
    });
  }

  

  async function submit(isDraft = false) {
    const bookInfo = bookInfoRef?.current?.bookInfo;
    if (!bookInfo.cover) {
      message.warning("书籍封面不可为空");
      return;
    }
    if (!bookInfo.title) {
      message.warning("书籍名称不可为空");
      return;
    }
    if (!bookInfo.content) {
      message.warning("书籍内容不可为空");
      return;
    }
    if (!bookInfo.subCategory.length) {
      message.warning("书籍类目不可为空");
      return;
    }
    if (!bookInfo.classes.length) {
      message.warning("书籍可见班级不可为空");
      return;
    }
    questionsRef.current.validateQuestions().then((datas) => {

      if (datas.length < 8) {
        message.warning("题目数量不能少于8个");
        return;
      }

      submitBook({
        id,
        cover: bookInfo.cover,
        content: bookInfo.content,
        title: bookInfo.title,
        category: bookInfo.category,
        subCategory: bookInfo.subCategory,
        classNames: bookInfo.classes,
        isPublished: !isDraft,
        questions: datas.map((d) => ({
          id: d.id,
          title: d.title,
          remark: d.remark,
          type: "radio",
          options: getOptions(d),
        })),
      }).then(() => {
        message.success(id ? "更新成功" : "保存成功");
        navigate("/admin/book");
      });
    });
  }

  const submitAIContent = () => {
    if (content.length < 100) {
      message.error("文案不少于100字");
      return;
    }
    console.log(content, '!!!')
    setIsOpen(false);
  }

  return (
    <div className={styles.pageBody}>
      <div className={styles.pageContent}>
        <BookInfo
          id={id}
          ref={bookInfoRef}
          questionsRef={questionsRef}
        ></BookInfo>
        <div className={styles.pageRight}>
          <RenderQuestions ref={questionsRef} bookInfoRef={bookInfoRef} content={content} ></RenderQuestions>
        </div>
      </div>
      <div className={styles.pageAction}>
        <div className={styles.uploadAI}>
          <Button className={styles.uploadAIBtn} onClick={() => setIsOpen(true)}>上传原文本AI解析</Button>
          <text className={styles.uploadAIDesc}>可以上传自己写的书让AI解析</text>
        </div>
        <Upload {...uploadOptions}>
          <div className={styles.uploadBtns}>
            <Button>快速导入excel题库</Button>
            <Button icon={<DownloadOutlined />} onClick={downloadTemp}></Button>
          </div>
        </Upload>
        <Button onClick={() => submit(true)}>保存题目草稿</Button>
        <Button type="primary" onClick={() => submit(false)}>
          保存
        </Button>
      </div>
      <Modal
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        centered
        width={1504}
        wrapClassName={styles.modal}
      >
        <div className={styles.modalTitleContainer}>
          <img src={AIIcon} className={styles.titleIcon}/>
          <text className={styles.modalTitle}>AI智能解析文本模块</text>
          <text className={styles.modalSubTitle}>（请输入文案，不少于100字）</text>
        </div>
        <div className={styles.textWrap}>
          <Input.TextArea
            onChange={(e) => setContent(e.target.value)}
            value={content}
            count={{
              show: true,
            }}
          />
          <Button className={styles.submitBtn} onClick={submitAIContent}>
            确定上传文本AI解析
          </Button>
      </div>
      </Modal>
    </div>
  );
}

export default observer(AdminBookCreate);
