export function generateRateValue(value) {
    const rate = Number(value)
    if(Number.isInteger(rate)) return rate
    return Math.floor(rate*2) / 2;
}


export function countWords(s){
    console.log(s)
    if (!s) return 0
    s = s.replace(/(^\s*)|(\s*$)/gi," ");//exclude  start and end white-space
    s = s.replace(/\n/gi," "); // exclude newline with a start spacing
    s = s.replace(/[ ]{2,}/gi," ");//2 or more space to 1
    return s.split(" ").filter(function (str) {
      return str !== "";
    }).length;
    //return s.split(' ').filter(String).length; - this can also be used
}


export function countAvgScore(evaluation) {
    const total = (evaluation.contentScore + evaluation.organizationScore + evaluation.languageScore) / 3
    return total
}
export function downloadBlob(res, fileName) {
  const downloadUrl = window.URL.createObjectURL(res);
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.download = `${fileName}.xlsx`;
  document.body.appendChild(link);
  link.click();

  link.remove();
  window.URL.revokeObjectURL(downloadUrl);
}

export function genScore(number) {
    return (Math.round(number * 10) / 10).toFixed(1)
}

export function genPopContent(evaluations) {
    return <div>
        {
            evaluations.map((evaluation, index) => {
                return (
                    <div>
                        <p style={{fontWeight: 'bold'}}>{evaluation.creator && evaluation.creator.username}</p>
                        <p>Content ({evaluation.contentScore}); Organization ({evaluation.organizationScore}); Language ({evaluation.languageScore})</p>
                        <p>Comment: {evaluation.content}</p>
                    </div>
                )
            })
        }
    </div>
}