import { Outlet } from 'react-router';
import { ConfigProvider } from 'antd';

import './css/index.css';

function Root() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#FF6D45',
        },
      }}
    >
      <Outlet />
      
    </ConfigProvider>
  );
}

export default Root;
