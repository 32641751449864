import { observer } from "mobx-react";
import { Space, Input, Button, message, Modal, Pagination } from "antd";
import styles from "../../css/adminReflectionDetail.module.scss";
import { useCallback, useContext, useEffect, useState } from "react";
import { getReflection, markAction, readReflection } from "../../utils/request";
import { useNavigate, useParams } from "react-router";
import { LayoutContext } from "../auth";

function AdminReflectionDetail() {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [rating, setRating] = useState();
  const [user, setUser] = useState({});
  const [book, setBook] = useState({});
  const [progress, setProgress] = useState({});
  const [reflection, setReflection] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const { id } = useParams();
  const { setHead } = useContext(LayoutContext);
  const navigate = useNavigate();

  const getReflectionInfo = useCallback(() => {
    getReflection(id).then((res) => {
      const result = res.data;
      setReflection({
        content: result.content,
        id: result.id,
        mark: result.mark,
        rating: result.rating,
        title: result.title,
      });
      setRating(result.rating);
      setProgress(result.progress);
      setUser(result.user);
      setBook(result.book);
      setTitle(result.title);
      setContent(result.content);
    });
  }, [id]);

  useEffect(() => {
    getReflectionInfo();
    setHead({
      title: "读后感打分",
    });
  }, [setHead, getReflectionInfo]);
  
  function onRatingChange(e) {
    if (e.target.value > 30) {
      setRating(30);
    } else if (e.target.value < 0) {
      setRating(0);
    } else {
      setRating(e.target.value);
    }
  }
  function showConfirmModal() {
    if (!rating && rating !== 0) {
      message.error("请输入分数");
      return;
    }
    if (reflection.rating) {
      message.success("已经打分,请勿重复提交");
      return;
    }
    setIsOpen(true);
  }
  function showSuccessModal() {
    setIsSuccessOpen(true);
    setTimeout(() => {
      setIsSuccessOpen(false);
      navigate(-1);
    }, 1500);
  }
  function submit() {
    markAction({
      id: id,
      rating: rating,
    }).then(() => {
      readReflection({ id }).then(() => {
        setIsOpen(false);
        showSuccessModal();
      });
    });
  }
  
  return (
    <div className={styles.page}>
      <div className={styles.studentInfo}>
        <p>
          {user.username} {user.class?.name}
        </p>
        <p>阅读的书籍名：{book.title}</p>
        <p>答题分数是{progress.rating}分 </p>
        {!!reflection.rating && <p>读后感分数是{reflection.rating}分 </p>}
      </div>
      <div className={styles.reflectionInfo}>
        <p className={styles.reflectionTitle}>
          <span>标题</span>
          <span>{title}</span>
        </p>
        <div className={styles.reflectionContent}>
          <span>正文</span>
          <p>{content}</p>
        </div>
        <div className={styles.action}>
          <Space.Compact style={{ width: "375px" }}>
            <Input
              type="number"
              placeholder="请输入分数"
              min={0}
              max={30}
              value={rating}
              onChange={onRatingChange}
            />
            <Button type="primary" onClick={showConfirmModal}>
              提交
            </Button>
          </Space.Compact>
        </div>
      </div>
      <Modal
        open={isOpen}
        wrapClassName={styles.confirmModal}
        footer={null}
        width={467}
        onCancel={() => setIsOpen(false)}
        centered
      >
        <p className="title">- 读后感评分 -</p>
        <p className="rating">{rating}分</p>
        <div className="submitRating" onClick={submit}>
          提交评分
        </div>
      </Modal>
      <Modal
        open={isSuccessOpen}
        maskClosable={false}
        closable={false}
        wrapClassName={styles.successModal}
        footer={null}
        width={367}
        centered
      >
        <p className="title">- 已成功评分 -</p>
      </Modal>
    </div>
  );
}
export default observer(AdminReflectionDetail);
