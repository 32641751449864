import styles from '../../../css/adminBookCreate.module.scss'
import AdminUpload from "../../../components/AdminUpload";
import {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useContext,
  useCallback,
} from "react";
import { getCategories, getClasses, getBookInfo } from "../../../utils/request";
import { Row, Col, Input, Select } from "antd";
import { LayoutContext } from "../../auth";
const BookInfo = forwardRef(({ id, questionsRef }, ref) => {
  const [bookInfo, setBookInfo] = useState({
    cover: "",
    title: "",
    content: "",
    category: "",
    subCategory: [],
    classes: [],
  });
  const { setHead } = useContext(LayoutContext);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setBookInfo((prevBook) => ({
      ...prevBook,
      [name]: value,
    }));
  }, []);
  const updateBook = useCallback((bookInfo) => {
    setActiveCategory(bookInfo.categoryId);
    setBookInfo({
      cover: bookInfo.cover,
      title: bookInfo.title,
      content: bookInfo.filePath,
      category: bookInfo.categoryId,
      subCategory: bookInfo.subCategoryId,
      classes: bookInfo.classIds,
    });
  }, []);
  const getBook = useCallback(() => {
    if (!id) return;
    getBookInfo(id).then((res) => {
      const bookInfo = res.data;
      updateBook({
        ...bookInfo,
        categoryId: bookInfo.subcategory[0].category.name,
        subCategoryId: bookInfo.subcategory.map((k) => k.name),
        classIds: bookInfo.classes.map((k) => k.name),
      });

      setTimeout(() => {
        if (questionsRef.current) {
          questionsRef.current.setQuestionsData(
            bookInfo.questions.map((questionItem, i) => {
              const correctValue = questionItem.options.findIndex(
                (k) => k.isCorrect
              );
              return {
                index: i,
                id: questionItem.id,
                title: questionItem.title,
                remark: questionItem.remark,
                options: questionItem.options,
                correctValue: correctValue,
              };
            })
          );
        }
      }, 20);
    });
  }, [id, questionsRef, updateBook]);

  const refreshCategories = useCallback(() => {
    return getCategories().then((res) => {
      setAllCategories(res.data.categories);
      setCategories(
        res.data.categories.map((k) => ({
          label: k.name,
          value: k.name,
        }))
      );
      setActiveCategory(res.data.categories[0].name);
      handleChange({
        target: { name: "category", value: res.data.categories[0].name },
      });
      setSubCategories(
        res.data.categories[0].subcategories.map((k) => ({
          label: k.name,
          value: k.name,
        }))
      );
    });
  }, [handleChange]);

  useEffect(() => {
    setHead({
      title: "新增题库",
    });
    refreshCategories()
      .then(() => refreshClasses())
      .then(() => getBook());
  }, [setHead, refreshCategories, getBook]);
  const [allCategories, setAllCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState();
  const [classes, setClasses] = useState([]);

  useImperativeHandle(ref, () => ({
    bookInfo,
    updateBook,
  }));

  function handleClassChange(e) {
    handleChange({ target: { name: "classes", value: e } });
  }
  function refreshClasses() {
    return getClasses().then((res) => {
      setClasses(
        res.data.map((k) => ({
          label: k.name,
          value: k.name,
        }))
      );
    });
  }

  function handleCategoryChange(name) {
    setActiveCategory(name);
    const currentCategory = allCategories.find((k) => k.name === name);
    setSubCategories(
      currentCategory.subcategories.map((k) => ({
        label: k.name,
        value: k.name,
      }))
    );
    handleChange({ target: { name: "category", value: name } });
    handleChange({ target: { name: "subCategory", value: [] } });
  }
  function handleSubCategoryChange(e) {
    handleChange({ target: { name: "subCategory", value: e } });
  }

  function onCoverSuccess(e) {
    handleChange({ target: { name: "cover", value: e.path } });
  }
  function onContentSuccess(e) {
    handleChange({ target: { name: "content", value: e.path } });
  }

  return (
    <div className={styles.pageLeft}>
      <Row align="middle">
        <Col className="gutter-row" span={3}>
          书籍封面
        </Col>
        <Col className="gutter-row" span={12}>
          <AdminUpload
            types={["png", "jpg", "jpeg"]}
            previewUrl={bookInfo.cover}
            onSuccess={onCoverSuccess}
          ></AdminUpload>
        </Col>
      </Row>
      <Row align="middle">
        <Col className="gutter-row" span={3}>
          <div>书籍名称</div>
        </Col>
        <Col className="gutter-row" span={12}>
          <Input
            placeholder="书籍名称"
            name="title"
            value={bookInfo.title}
            onChange={handleChange}
          ></Input>
        </Col>
      </Row>
      <Row align="middle">
        <Col className="gutter-row" span={3}>
          <div>书籍内容</div>
        </Col>
        <Col className="gutter-row" span={12}>
          <AdminUpload
            types={["pdf"]}
            previewUrl={bookInfo.content}
            onSuccess={onContentSuccess}
          ></AdminUpload>
        </Col>
      </Row>
      <Row align="middle">
        <Col className="gutter-row" span={3}>
          <div>书籍类目</div>
        </Col>
        <Col className="gutter-row" span={4}>
          <Select
            placeholder="一级类目"
            value={activeCategory}
            onChange={handleCategoryChange}
            options={categories}
          />
        </Col>
        <Col span={8}>
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="书籍类目"
            value={bookInfo.subCategory}
            onChange={handleSubCategoryChange}
            options={subCategories}
          />
        </Col>
      </Row>
      <Row align="middle">
        <Col className="gutter-row" span={3}>
          <div>可见班级</div>
        </Col>
        <Col className="gutter-row" span={12}>
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="可见班级"
            value={bookInfo.classes}
            onChange={handleClassChange}
            options={classes}
          />
        </Col>
      </Row>
    </div>
  );
});
export default BookInfo